<template>
    <v-row>
        <v-col cols="12">
            <v-data-table :headers="headersColaboradores" :items="avaliados" :loading="isLoadingBtnProcess"
                class="table-kitchen-sink" items-per-page-text="Linhas por página"
                no-results-text="Nenhum registro encontrado" loading-text="Carregando... Por favor, espere" :footer-props="{
                    showFirstLastPage: true,
                    'items-per-page-text': 'Linhas por página',
                }" mobile-breakpoint="0">
                <template v-slot:no-data>
                    <v-alert :value="true" color="error">
                        <span class="text-white">Nenhum registro encontrado</span>
                    </v-alert>
                </template>
                <!-- NÍVEL -->
                <template v-slot:[`item.id_nivel`]="{ item }">
                    <span>
                        {{ retornaNiveis(item.id_nivel) | capitalize() }}
                    </span>
                </template>
                <!-- CARGO -->
                <template v-slot:[`item.cargo`]="{ item }">
                    <span>
                        {{ item.cargo | capitalize() }}
                    </span>
                </template>
                <!-- SETOR -->
                <template v-slot:[`item.setor`]="{ item }">
                    <span>
                        {{ item.setor | capitalize() }}
                    </span>
                </template>
                <!-- GESTOR -->
                <template v-slot:[`item.gestor`]="{ item }">
                    <v-chip small :color="item.gestor == true ? 'warning' : 'info'">
                        {{ item.gestor == true ? "Sim" : "Não" }}
                    </v-chip>
                </template>
                <template v-slot:header.aa="{ header }">
                    <v-checkbox :on-icon="'mdi-checkbox-marked'" v-model="tiposAvaliacoes['aa']"
                        @click="toggleSelectTypeAvaliation('aa', $event)">
                        <template v-slot:label>
                            <span style="font-size: 12px; color: #5e5669de">{{
                                header.text
                            }}</span>
                        </template>
                    </v-checkbox>
                </template>
                <template v-slot:header.ap="{ header }">
                    <v-checkbox :on-icon="'mdi-checkbox-marked'" v-model="tiposAvaliacoes['ap']"
                        @click="toggleSelectTypeAvaliation('ap', $event)">
                        <template v-slot:label>
                            <span style="font-size: 12px; color: #5e5669de">{{
                                header.text
                            }}</span>
                        </template>
                    </v-checkbox>
                </template>
                <template v-slot:header.as="{ header }">
                    <v-checkbox :on-icon="'mdi-checkbox-marked'" v-model="tiposAvaliacoes['as']"
                        @click="toggleSelectTypeAvaliation('as', $event)">
                        <template v-slot:label>
                            <span style="font-size: 12px; color: #5e5669de">{{
                                header.text
                            }}</span>
                        </template>
                    </v-checkbox>
                </template>
                <template v-slot:header.al="{ header }">
                    <v-checkbox :on-icon="'mdi-checkbox-marked'" v-model="tiposAvaliacoes['al']"
                        @click="toggleSelectTypeAvaliation('al', $event)">
                        <template v-slot:label>
                            <span style="font-size: 12px; color: #5e5669de">{{
                                header.text
                            }}</span>
                        </template>
                    </v-checkbox>
                </template>

                <!-- AUTOAVALIAÇÃO -->
                <template v-slot:[`item.aa`]="{ item }">
                    <v-checkbox v-model="item.avaliacao.aa"></v-checkbox>
                </template>
                <!-- Avaliação de Pares -->
                <template v-slot:[`item.ap`]="{ item }">
                    <v-checkbox v-model="item.avaliacao.ap"></v-checkbox>
                </template>
                <!-- Avaliação de Subordinado -->
                <template v-slot:[`item.as`]="{ item }">
                    <v-checkbox v-model="item.avaliacao.as"></v-checkbox>
                </template>
                <!-- Avaliação de Líder -->
                <template v-slot:[`item.al`]="{ item }">
                    <v-checkbox v-model="item.avaliacao.al"></v-checkbox>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import store from "@/store";
import { ref, computed } from "@vue/composition-api";

export default {
    name: 'ComportamentalColaboradores',
    setup() {
        // Variables
        const headersColaboradores = ref([
            { text: "Nome", value: "nome" },
            { text: "Cargo", value: "cargo" },
            { text: "Setor", value: "setor" },
            { text: "Nível", value: "id_nivel" },
            { text: "Gestor", value: "gestor" },
            { text: "Autoavaliação", value: "aa", align: "center", align: "start" },
            { text: "Avaliação de Pares", value: "ap" },
            { text: "Avaliação de Subordinado", value: "as" },
            { text: "Avaliação de Líder", value: "al" },
        ]);
        const isLoadingBtnProcess = ref(false);
        const tiposAvaliacoes = ref([
            { aa: false },
            { ap: false },
            { as: false },
            { al: false },
        ]);

        // Computed
        const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
        const avaliados = computed(
            () => store.getters["avdColaboradores/getColaboradores"]
        );
        const listaNiveis = computed({
            get() {
                return store.getters['avdNiveis/getNiveis'];
            }
        });

        // Function
        function toggleSelectTypeAvaliation(tipo) {
            avaliados.value.map((el) => {
                el.avaliacao[tipo] = tiposAvaliacoes.value[tipo];
            });
        };
        function retornaNiveis(id_nivel = Number) {
            if (listaNiveis.length < 1) return 'Sem nível';

            const nivel = listaNiveis.value.find((el) => el.id === id_nivel);

            return nivel && typeof nivel == 'object' ? nivel.descricao : 'Sem nível';
        };
        function processData() {
            const arrayAvaliados = []

            avaliados.value.forEach(el => {
                const avaliado = {
                    id: el.id,
                    avaliacao: el.avaliacao
                }

                arrayAvaliados.push(avaliado)
            })

            const data = {
                id_avaliacao: avaliacao.value.id,
                avaliados: arrayAvaliados
            }

            return data
        }
        function salvar() {
            isLoadingBtnProcess.value = true;

            const data = processData()

            store
                .dispatch("avdColaboradores/salvarAvaliacoesDeColaboradores", data)
                .catch(() => {
                    store.dispatch("snackBarModule/openSnackBar", {
                        color: "error",
                        timeout: 5000,
                        text: "Houve um erro ao salvar a configuração comportamental. Por favor, mais tarde tente novamente.",
                    });
                })
                .finally(() => {
                    isLoadingBtnProcess.value = false;
                });
        };
        function validate() {
            salvar();

            return true;
        };

        return {
            // Variables
            headersColaboradores,
            isLoadingBtnProcess,
            tiposAvaliacoes,

            // Computed
            avaliacao,
            avaliados,
            listaNiveis,

            // Function
            toggleSelectTypeAvaliation,
            retornaNiveis,
            salvar,
            validate
        }
    }
}
</script>

<style></style>